

.blueCard {
border-radius: 12px;
    background: linear-gradient(180deg, #1E1E1E 0%, rgba(30, 30, 30, 0.12) 50.52%, rgba(30, 30, 30, 0.77) 100%);
    box-shadow: -2px 2px 4px 0px rgba(51, 127, 255, 0.29) inset, -2px -4px 4px 0px #121212 inset;
    width: 23%;
    margin: 0.5rem 0 0.5rem 0;
 }

 

.orangeCard {
    box-shadow: 1px 2px 4px 0px rgba(235, 94, 40, 0.12) inset, 1px -4px 4px 0px #121212 inset;
    width: 30%;
    border-radius: 1rem;
    width:30%;
    margin: 0.5rem 0 0.5rem 0;
 }



 @media screen and (max-width:1000px) {
    .blueCard{
        width: 49%;
        
    }

    .orangeCard{
        width: 100%;
    }
 }
 
@media screen and (max-width:700px){
    .blueCard{
        width: 100%;
        // max-height: 20rem;
    }

    .orangeCard{
        width: 100%;
    }
}
