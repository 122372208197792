@import "./mixin.scss";


.tealHeader_sm {
    @include darkHeader_div;
    padding: 1rem;
}

.tealHeader_md {
    @include darkHeader_div;
    padding: 1.5rem;

}

.tealHeader_lg {
    @include darkHeader_div;
    padding: 1.8rem;
}