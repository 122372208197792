@import "../../utils/mixin.scss";
@import "../../utils/colors.scss";

.contact{
    text-align: center;
    @include homepage_spacer;

        div:nth-child(2){
            margin-bottom: 5%;
        }
    }

    .contact-cards{
        @include useFlexBetween;
    }

 .processCard-content_contact {
    img{
        width: 2rem;
        margin-bottom: 4rem;
    }
     text-align: left;
     @include useFlexCenter;
     flex-direction: column;
     padding: 1rem;

     div:nth-child(2) {
         margin-bottom: 10%;
     }

     div:nth-child(3) {
         margin-bottom: 5%;

     }

     div:nth-child(3) {
         font-weight: 100;
     }

 }
 .contact-forms{
    h4{
        text-align: center;
        font-size: 1.5rem;
        font-weight: 200;
    }
 }

 .form-fields{
    margin: 0 20% auto;
    text-align: center;

    .name-email{
        @include useFlexBetween;
        flex-wrap: wrap;
        
        .name-email_width{
            width: 48%;
        }
     }

        .boxName {
                text-transform: uppercase;
                text-align: left;
                font-size: 1rem;
                padding: 1rem 0 1rem 0;
            }

    input {
        text-align: left;
            border-radius: 1rem;
            border: 1px solid $faintedWhite;
            padding: 1.3rem;
            background-color: $faintedWhite;
            outline: none;
            border: 1px solid $lightblack;
            color: #fff;
            width: 100%;
        }

        textarea{
            text-align: left;
                border-radius: 1rem;
                border: 1px solid $faintedWhite;
                padding: 1.3rem;
                background-color: $faintedWhite;
                outline: none;
                border: 1px solid $lightblack;
                color: #fff;
                max-width: 100%;
                min-width: 100%;
                max-height: 50%;
                min-height: 50%;
                margin: 0rem 0 1rem 0;

        }

 }


 @media screen and (max-width: 1000px){
    .contact-cards{
        display: block;
    }

    
        .contact-forms{
            h4{
                padding: 2rem 0 2rem 0;
                font-size:1rem;
            }
        }

        .form-fields{
            margin: 0;
            .name-email{
                // display: block;
                
                min-width: 100%;
            }

            .boxName{
                font-size: 0.88rem;
            }

            .name-email_width{
                min-width: 100%;
            }
            input {
                min-width: 100%;
            }
        }

    
 }

