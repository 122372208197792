@import "../../utils/mixin.scss";
.mission-and-vision, .mission-and-vision-reverse{
    @include useFlexBetween;
    gap: 3%;
    // width: 100%;
    margin: auto;
    margin-bottom: 5%;
    .desc {
        font-size: 1rem;
        color: #FFFFFF;
    }

    .mission {
        border-radius: 12px;
        background: linear-gradient(180deg, #1E1E1E 0%, rgba(30, 30, 30, 0.12) 50.52%, rgba(30, 30, 30, 0.772358) 100%);
        padding: 2% 2%;
        box-shadow: 2px 2px 4px 0px #FFFFFF4A inset;
        width: 80%;
    }

    .image {
        width: 25%;
        img {
            width: 100%;
            height: 100%;
        }
    }

    .small {
        display: flex;

        img {
            margin-right: 30px;
            margin-left: 6px;
        }

        box-shadow: 0px -1px 4px 0px #000000 inset;
        width: 300px;
        color: #EB5E28;
        padding: 8px 32px 8px 6px;
        border-radius: 100px;
    }

    button {
        @include bigButton;
    }

    button:hover {
        background: linear-gradient(180deg, #EB5E28 0%, #732000 100%);
    }

        
    @media (max-width: 800px) {
        flex-direction: column-reverse; 
        .mission {
            width: 100%;
            padding: 2% 3% 5%;
        }
        
        .image {
            width: 100%;
            margin-bottom: 3%;
        }
        button{
            width: 100%;
            margin: auto;
            background: linear-gradient(180deg, #EB5E28 0%, #732000 100%);
            color: #FFFFFF;
        }
    }
}
@media ((min-width: 799px) and (max-width: 1500px)) {
    .mission-and-vision-reverse {
        flex-direction: row-reverse;

    }
}
@media ((min-width: 1500px)) {
    .mission-and-vision-reverse {
        flex-direction: row-reverse;
    }
}