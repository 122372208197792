
.map{
    width: 95%;
    border-radius: 10px;
    border: 5px solid #EB5E28;
    margin:auto;
    margin-top: 6%;
}
.wrapper{
    padding: 1% 2%;

    @media (max-width: 500px) {
        padding: 1%;
    }
}
// .our-routes{
   
// }