@import "../../utils/mixin.scss";

.header-container{
    padding: 1rem;
    align-content:center;
    margin: 0.3rem 10% 0 10%;
    @include useFlexBetween;
    background: rgba(24, 24, 24, 0.1);
    border-radius: 3rem;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(100px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid #252525;
}

.hamburger{
    display: none;
    font-size: 2rem;
    cursor:pointer;
}

.nav {
   ul {
       @include useFlexBetween;
        align-content: center;
       column-gap: 4rem;

       a {
        text-decoration: none;
        color: white;
       }
       

        li {
           
            list-style-type: none;
            font-size: 0.75rem;
        }
    }
}

.signIn {
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.5);
    padding:0.75rem;
    width:6 * 1rem;
    border-radius: 10px;
    background-color: $bodyBlack;
    cursor: pointer;
    

}



@media screen and  (max-width: 1200px){

    .menu-bar{
       
        align-content: center;
        margin: 0 10% auto ;
        background: rgba(24, 24, 24, 0.1);
        backdrop-filter: blur(100px);
        -webkit-backdrop-filter: blur(5px);

        a {
            text-decoration: none;
            color: white;

        }

        ul{
             padding: 1rem 0 1rem 0;

            li{
                list-style-type: none;
                padding: 0.5rem;
                font-size: 0.7rem;

                &:hover{
                    background-color: $darkOrange;
                }
             
            }
        }
    }
        .nav {
               display: none;
        }

        .hamburger{
            display: block;
        }
}