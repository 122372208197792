@import "../../utils/mixin.scss";


.pricing{
    padding: 3%;
    background: linear-gradient(0deg, #1E1E1E, #1E1E1E),
    linear-gradient(0deg, #252525, #252525);
    border-radius: 50px;
    hr{
        border: 1px solid #252525
    }
    .distance{
        font-size: 1.2rem;
        color: #FFFFFF8C;
    }
    span{
        font-size: 3rem;
        color: #fff;
    }
    .type{
        color:  #eb5e28;
        font-size: 1.2rem;
    }
    ul li {
        list-style-image: url("../../assets/images/check.svg");
        margin-top: 6%;
        color: #FFFFFF8C;
    }
    button{
        background: #808080;
        width: 100%;
        padding: 5%;
        margin-top: 20px;
        border-radius: 50px;
        color: #181818;
        cursor: pointer;
    }
}
.pricing:hover{
    border: 1px solid #fff;
    button{
        background-color: #fff;
    }
}