

.container{
    padding:3px 5% 0 5%;
    // border: 1px solid red;
}


@media screen and (max-width: 750px) {
.container {
        padding: 3px 2.5% 0 2.5%;
        // border: 1px solid red;
    }
}
    