@import "../../utils/mixin.scss";


.accordion-container{
    @include useFlexBetween;
    margin: 0 20% auto;
    cursor: pointer;

    .img-down{
        transform: rotate(270deg);
    }
    .img-up{
        transform: rotate(90deg);
    }

    &:hover{
        background-color: rgba($color: $darkOrange, $alpha: 0.2);
        transition: 0.33s;
    }

}
.accordion-content{
    font-size: 1rem;
    margin: 0 20% auto;
    padding: 0;
    text-align:left;

}

@media screen and (max-width: 1000px) {
    .accordion-container{
        margin: 0 7% auto;
    }

    .accordion-content{
        margin: 0 7% auto;
    }
}