
$teal-balck : #1e1e1e;

$linearOrange :  linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 3px) calc(100% - 4px) no-repeat,
linear-gradient(transparent 0%, #eb5e28 0%, #732000 100%);

$lightOrange : #eb5e28;

$darkOrange : #732000;

$bodyBlack : #181818;

$skyblue : #337FFF;

$faintedWhite: #252525;

$lightblack: #808080;

$lightblack2: #1E1E1E;

$deepBlue: #337FFF;