@import "../../utils/mixin.scss";
@import "../../utils/colors.scss";

.cardContainer{
    border: 1px solid $faintedWhite;
    padding: 1rem;
    max-width: 49%;
    border-radius: 2rem;
    margin: 1rem 0 1rem 0;
}

@media screen and (max-width: 1000px){
    .cardContainer{
        max-width: 100%;

    }
}



