@import "../utils/colors.scss";

.avatar-profiles{

        .avatar-container{

            max-width: 150px;
            max-height: 150px;
            min-width: 150px;
            min-height: 150px;
            border-radius: 50%;
            border: 5px solid $lightOrange;
            padding: 1rem;
            overflow: hidden;

                img {
                    max-width: 150px;
                    max-height: 150px;
                    min-width: 150px;
                    min-height: 150px;
                    border-radius: 50%;
                }
        }
        .name{
            font-size: 1.1rem;
            padding: 1rem;
            display: block;
        }
        .title{
            font-size: 1rem;
            font-weight: 100;
        }
}


@media screen and (max-width: 1000px){
    .avatar-profiles{
        .avatar-container{
            img {
                max-width: 100px;
                    max-height: 100px;
                    min-width: 100px;
                    min-height: 100px;
            }
        }
    }
}