@import "../../utils/mixin.scss";
@import "../../utils/colors.scss";

.offer_exclusive{

    padding: 2rem;
    width: 30%;
    border-radius: 32px;
    border: 1px solid #252525;
    background: #eb5e28;
    text-align: left;
    background: linear-gradient(9deg, rgba(235, 94, 40, 1) 5%, #252525 40%);
    cursor: pointer;

    p:nth-child(3){
        color: $deepBlue;
    }

    .title{
        font-size: 2.6rem;
        
    }

    .title-content{
        padding-bottom: 15rem;
        font-weight: 200;
        font-size: 0.85rem;
    }

    &:hover{
        border: 1px solid rgba($color: #fff, $alpha: 0.5);
    }

}

.offer{
    padding: 2rem;
    border: 1px solid  $faintedWhite;
    width: 30%;
    background-color: $lightblack2;
    border-radius: 2rem;

    &:hover{
        border: 1px solid rgba($color: #fff, $alpha: 0.5);
        color: #fff;
        .get-started {
                background-color: #fff;
            }
     

    }
    cursor: pointer;

    .kilometre {
  
        @include useFlexRight;
        align-items: center;
        column-gap: 10px;
        opacity: 1;
        color: rgba($color: #fff, $alpha: 0.5);
        padding: 1rem 0 auto;
        

        span {    
            font-size: 2.5rem;
            color: #fff;
        }

    }

    .title{
        color: $lightOrange;
        text-align:left;
        margin: 2rem 0 auto;

    }

    .trip-benefits{
       margin-bottom: 2rem;

    
    ul{
        text-align: left;
        padding: 0;
        li {
            list-style-type: none;

            span{
                margin-left: 10px;
                color: $lightblack;
            }
        }
    }
}

    .get-started {
        margin-top: 2rem;
        background-color: $lightblack;
        padding: 1rem;
        color: #000;
        border-radius: 3rem;
        // &:hover{
        //     background-color: #fff;
        // }
    }
}

@media screen and (max-width: 1000px){
    .offer_exclusive, .offer{
        width: 100%;
        margin: 1rem 0 1rem 0;
    }
}