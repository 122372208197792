@import "./colors.scss";

@mixin useFlexCenter {
    display: flex;
    justify-content: center;
}

@mixin useFlexRight {
    display: flex;
    justify-content:start;
}

@mixin useFlexEnd {
    display: flex;
    justify-content: end;
}

@mixin useFlexBetween {
    display: flex;
    justify-content: space-between;
}

@mixin useFlexAround {
    display: flex;
    justify-content: space-around;
}

@mixin buttonSize ($paddingSize, $borderSize, $width: 6rem){
    padding: $paddingSize;
    border-radius: 2rem;
    background-color: $bodyBlack;
    color: white;
    width: $width;
    outline: none;
    border: 1px solid rgba($color: $lightOrange, $alpha: 0.3);
    cursor: pointer;
  
}

@mixin tealBlackStyle {
    background-color: $teal-balck;
    box-shadow: 0px 0px 4px 0px  #312622  inset;
}

@mixin darkHeader_div {
    background-color: $teal-balck;
    border-radius: 100px;
    background: #1E1E1E;
    box-shadow: 0px -1px 4px 0px #000 inset, 0px 1px 4px 0px rgba(51, 127, 255, 0.36) inset;
    color: $lightOrange;
    display: inline-block;
}
@mixin useFlexColumnCenter{
    @include useFlexCenter;
    flex-direction: column;
    align-items: center;
}
@mixin useButton{
    @include buttonSize(12px, 0);
    color: #EB5E28;
    background: #1E1E1E;
    padding: 8px, 12px, 8px, 12px;
    border-radius: 100px;
}
@mixin bigButton{
    @include useButton;
    width: 250px;
    margin-top: 20px;
    color: #FFFFFF;
     padding: 20px;
    background: linear-gradient(180deg, #EB5E28 0%, 100%);
    border: 1px solid#EB5E28;
}

@mixin homepage_spacer ($gap: 2.5rem){
    @include useFlexCenter;
        flex-direction: column;
        text-align: center;
        row-gap:$gap;
        padding-top: 2.5rem;
}