@import "../utils/mixin.scss";
@import "../utils/colors.scss";

// @mixin buttonSize ($paddingSize, $borderSize, $width: 6rem, $borderColor: white, $backgroundColor : $linearOrange) 

.sm {
  @include buttonSize(1rem, 1, 7rem );
  font-size: 0.8rem;
  font-weight: 100;

  &:hover{
      background: linear-gradient(180deg, #EB5E28 0%, #732000 100%);
  }
}

.md {
    @include buttonSize(1rem, 2.5px);

    &:hover {
            background: linear-gradient(180deg, #EB5E28 0%, #732000 100%);
        }
}

.lg {
    @include buttonSize(1.5rem, 3px);
    width:20rem;
    border-radius: 3rem;

    &:hover {
            background: linear-gradient(180deg, #EB5E28 0%, #732000 100%);
        }
}


@media screen and (max-width: 1000px){
    .sm{
        width: 100%;
        background: linear-gradient(180deg, #EB5E28 0%, #732000 100%);
    }
}