
.map-container {
    height: 100%;
    width: 100%;
    .leaflet-container {
      height: 100%;
    }
.custom-popup {
    padding: 10px;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
}
  