

@import "../../utils/mixin.scss";
@import "../../utils/colors.scss";

.pricing_range{
    text-align: center;
    @include homepage_spacer;

    h2 {
        font-size: 1rem;
    }
}

.offers-desc{
    @include useFlexBetween;
    margin: 5% 0 5%  0;
}

.one-way, .round-trip{
    text-align: justify;
    width: 47%;
    div:nth-child(1){
        text-align: center;
    }
}

 .subscribeToRoute {
    text-align: justify;
     div:nth-child(1) {
         text-align: center;
     }
 }

 @media screen and (max-width: 700px){
    .offers-desc{
        display: block;
    }

    .one-way, .round-trip{
         width: 100%;
         font-size: 1rem;
    }

    .subscribeToRoute{
        font-size: 1rem;
    }
 }