@import "../../utils/mixin.scss";

.about-us{
    @include useFlexColumnCenter;
    text-align: center;
    margin: auto;
    margin-top: 3%;
    .p1{font-size: 1.7rem;}
    .p2{
        font-size: 1.2rem;
        width: 95%;
        margin-bottom: 50px;
    }
    span{color: gray;}
    .about{
        margin: 2%;
            margin-bottom: 50px;
            width: 200px;
            color: #EB5E28
    }
    @media (max-width: 700px) {
        width: 100%;
        .p1{font-size: 1.5rem;}
        .p2{font-size: 1rem;}
        
    }
}
.meet-the-team{
   @include useFlexColumnCenter;
   width: 49%;
   margin: 10% auto;
   p{
        margin-top: 9%;
        font-size: 1.5rem;
        text-align: center;
        width: 90%
   }
   button{
    @include useButton;
    box-shadow: 0px 1px 4px 0px #FFFFFF5C inset;
    width: 150px;
   }
   @media (max-width: 700px) {
    width: 96%;
    p{
        font-size: 1.2rem;
    }
}
}
.cards{
    display: flex;
    justify-content: center;
    gap: 2%;
    width: 90%;
    flex-wrap: wrap;
    margin: 5% auto;
    .card {
         @include useFlexBetween;
        width: 350px;
        margin-bottom: 5%;
        box-shadow: 2px 2px 4px 0px #FFFFFF4A inset;
        background: #121212;
        border-radius: 12px;
        padding: 30px;
    }
    .card:hover{
        box-shadow: 2px 2px 4px 0px #EB5E28 inset;
    }
    p{
        font-size: 1.5rem;
    }
    .sub{
        font-size: 1rem;
        color: #808080;
    }
    .card-image{
        width: 30%;
        margin-top: 3%;
        img{
            width: 100%;
            border-radius: 100%;
            background: #fff;
            border: none;
        }
        img:hover{
            background-color: #EB5E28;;
        }
    }
    .linkedIn{
        border: 1px solid #fff;
        border-radius: 50px;
        padding: 5px;
        width: 20px;
        height: 20px;
    }
    @media (max-width: 700px) {
        .card{
            margin-bottom: 15%;
        }
    }

}
.team{
    button{
        @include useButton;
        width: 150px;
        box-shadow: 0px 1px 4px 0px #FFFFFF5C inset;
    }
    .team-header{
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p {
            margin-top: 90px;
            font-size: 1.5rem;
            color: #FFFFFF;
        }
    }
}
.about-us-page{
    padding: 1% 6%;
}