@import "../../utils/mixin.scss";
@import "../../utils/colors.scss";



.footer{
    margin-top: 3rem;

    ul{
        list-style-type: none;
        padding: 0;
    }

    li {
        font-size: 0.9rem;
        margin: 2rem 0 auto;
        color: #808080;
    }


    h3{
        text-align: center;
        font-size: 1rem;
        font-weight: 200;
    }

    .copyright{
        font-size: 1.1rem;
    }
    .link{
        text-decoration: none;
    }

    .line {
        background: #808080;
        min-height: 1.5px;
    }

    .logos{

        text-align: center;
        flex-wrap: wrap;
    }

    .socials{
            img {
                margin: 0.5rem;
                width: 3rem;
            }
    }
    
            .travelway-logo{
                width: 13rem;
            }


    
}

.quickly-navigate{
    font-weight: 200;
    font-size: 1rem;
    text-align:center;
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.footer-navs{

    margin: 0 10% auto;
    @include useFlexBetween;
    flex-wrap: wrap-reverse;
    width: 80%;
}

@media screen and (max-width: 1000px){

    .footer{

        .socials {
            img{

                width: 2rem;
            }
            }
        .travelway-logo{
            width: 8rem;

           
        }
    }
   
}

@media screen and (max-width: 700px){
    .footer-navs{
        display: block;
    }

        .quickly-navigate {
            font-weight: 200;
            font-size: 0.8rem;
            text-align: center;
            margin-bottom: 1rem
        }

    .footer{
            ul {
                    list-style-type: none;
                    padding: 0;
                }
            
                li {
                    font-size: 0.9rem;
                    margin: 0;
                    color: #808080;
                    text-align: center;
                }
    }
}
