@import "../../utils/mixin.scss";

.intro {
    @include useFlexColumnCenter;

    .services {
        margin-bottom: 50px;
        margin-top: 2%;
        width: 200px;
        color: #EB5E28
    }

    .services-second {
        @include useButton;
        box-shadow: 0px 1px 4px 0px #ffffff5c inset;
        margin-bottom: 50px;
        width: 300px;
    }

    p {
        font-size: 1.5rem;
        text-align: center;
        width: 42%;
    }

    .get-the-app {
        @include bigButton;
        width: 190px;
        margin-top: 30px;
    }

    .get-the-app:hover {
        background: linear-gradient(180deg, #eb5e28 0%, #732000 100%);
    }

    @media (max-width: 500px) {
        p {
            font-size: 1rem;
            width: 100%;
        }
        .get-the-app {
            width: 90%;
            color: #FFFFFF;
            background: linear-gradient(180deg, #EB5E28 0%, #732000 100%);
        }
    }
    @media screen and (min-width: 500px) and (max-width: 1200px) {
        width: 100%;
        p {
            width: 100%;
        }
        
    }
}

.double-cards {
    margin-top: 10%;
    margin-bottom: 10%;
}

.our-services {
    @media (max-width: 500px) {
        padding: 1%;
    }
}

.my-cards {
    @include useFlexBetween;
    margin-top: 20%;
    margin-bottom: 3%;
    gap: 3%;
    flex-wrap: wrap;
    justify-content: center;

    div {
        padding: 3%;
        margin-bottom: 2%;
        width: 400px;
    }

    hr {
        border: 1px solid #252525;
    }

    .first-card {
        background: linear-gradient(to bottom, #181818, #AD3A0E);
        border-radius: 50px;

        .p1 {
            color: #ffffff;
            font-size: 3rem;
        }

        .p2 {
            color: #ffffff;
        }

        .spots {
            margin-top: 200px;
            color: #337FFF;
        }
    }

    .first-card:hover {
        border: 1px solid #fff;
    }

    @media (max-width: 500px) {
        div {
            width: 100%;
            margin-bottom: 10%;
            border: 1px solid #fff;
            border-radius: 20px;
        }

        .first-card {
            border-radius: 20px;
        }
    }

    @media ((min-width: 900px) and (max-width:1000px)) {
        div {
            width: 300px;
        }
    }
}

.book-your-ride {
    text-align: center;
    margin: 10% auto;
    padding: 3% 10% 10%;
    background: linear-gradient(360deg,
            #EB5E28 -4.96%, rgba(49, 39, 35, 0) 92.61%);
    border-radius: 50px;

    p {
        font-size: 1.5rem;
    }

    .main {
        font-size: 4rem;
        width: 70%;
        margin: auto;
    }

    button {
        background: #fff;
        border: none;
        padding: 2% 3%;
        margin-top: 20px;
        border-radius: 50px;
        color: #181818;
        font-weight: bold;
        font-size: 1rem;
        cursor: pointer;
    }
    @media (max-width: 500px) {
        padding: 3% 10% 40%;
        margin: 20% 0;
        .main{
            font-size: 2rem;
            width: 90%;
            margin: 0;
        }
        p{
            font-size: 1rem;
        }
    }
}