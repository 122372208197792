@import "../../utils/mixin.scss";
@import "../../utils/colors.scss";

.card-label {
        @include tealBlackStyle;
        font-weight: 300;
        font-size: 0.8rem;
        display: inline-block;
        padding: 0.7rem;
        border-radius: 1.4rem;  
        margin-bottom: 2rem;
}

.desc {
    font-weight: 100;
    font-size: 0.9rem
}

@mixin img-container($width, $top, $imgWidth){
    max-width: $width;    
    margin-top: $top;
    img {
            width: $imgWidth;
        }
}

.homepageCard_container{

    p:nth-child(2){
        // font-size: 0.7rem;
        margin-bottom: 0.5rem;
    }
    p:nth-child(3){
        font-size: 0.88rem;
    }

    @include useFlexBetween;

    .img-container_laptop {
           @include img-container(100%, 20%, 100%);
          
        }

    .img-container_phone{
            @include img-container(100%, 3%, 100%)
    }
}

.welcome-card{
    background: linear-gradient(181deg, #EB5E28 1.73%, #7A2200 31.69%, rgba(24, 24, 24, 0.00) 73.24%);
    border-radius: 2rem;
    margin-top: 2rem;
    padding: 3% 8% 0 8% ;
}


.card_section-upper{
    text-align: center;

    p:nth-child(1){
        border-radius: 100px;
        background: rgba(30, 30, 30, 0.08);
        box-shadow: 0px 0px 4px 0px rgba(255, 176, 142, 0.32) inset;
        padding: 1rem;
        display: inline-block;
        font-weight: 100;
        font-size: 0.8rem;
    }

    p:nth-child(2){
        font-size: 1.8rem;
        margin: 0;
    }

    p:nth-child(3){
        font-weight: 100;
        font-size: 1rem;
    }
}

.card_section-lower {
    background-color: $skyblue;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    @include useFlexBetween;
    padding: 2rem;

    button {
        outline: none;
        padding: 1rem;
        border: none;
        border-radius: 2rem;
        width: 50%;
        font-size: 0.89rem;
        color: $lightOrange;
        background-color: #fff;
        @include useFlexBetween;

    }

    p:nth-child(1){
        font-size: 2.5rem;
        margin-bottom: 0;
        line-height: 1.4;
    }

    .inner-left , .inner-right{
        width: 45%;
    }

    .inner-left {
        p:nth-child(2){
            font-weight: 100;
        }
    }

    .inner-right {
        width:60%;
        img {
            width: 100%;
        }
    }
}

.how_works-section{

   @include homepage_spacer;

    .quick-journey{
        font-size: 3rem;
        span {
            opacity: 0.4;
        }
    }

        .how_works-div{
            color: $lightOrange;
            

        }

        .processCard-content{
            text-align: left;
            @include useFlexCenter;
            flex-direction: column;
            padding: 1rem;
           

            div:nth-child(1){
                margin-bottom: 50%;
            }

            div:nth-child(2){
                margin-bottom: 10%;

            }

            div:nth-child(3){
                font-weight: 100;
            }

        }

    .processes{
        @include useFlexBetween;
    }
}

.customer-container{
  @include homepage_spacer;

  div:nth-child(2){
    font-size: 1.5rem;
    margin: 0 20% auto;
  }

  .images{
    @include useFlexBetween;
    margin: 0 10% auto ;
  }
  .content {
    font-weight: 100;
    margin: 0 10% auto;
    font-size: 1.2rem;
  }
  .arrows{
    @include useFlexCenter;
    column-gap: 30px;
    img {
        width: 3rem;
    }
  }
}

.offerTypes{
    @include homepage_spacer;
    column-gap: 30px;
    flex-direction: row;
}

.faqs{
    @include homepage_spacer(0.44rem);


    div:nth-child(2){
        text-align: left;
        font-size: 2rem;
        text-align: center;
        margin: 0 20% auto;
        padding: 1rem;
    }

    .accordions{
        margin: 0;
    }

    div:nth-child(3){
        font-weight: 100;
        // border: 1px solid red;
    }
}

.intrastate-adventure{
    @include homepage_spacer(1rem);
    border-radius: 1rem;
    background: linear-gradient(0deg, #EB5E28 -4.96%, rgba(49, 39, 35, 0.00) 92.61%);
    padding: 4rem 0 2rem 0;

    div:nth-child(1){
        font-size: 3rem;
    }

    div:nth-child(2){
        font-size: 1rem;
    }

    span:nth-child(3){
        border-radius: 2rem;
        padding: 1rem;
        margin: 0 40% auto;
        display: inline-block;
        text-align: center;
        color: $bodyBlack;
        background-color: white;
        cursor: pointer;
    }
}


@media screen and (max-width: 1000px){


    .homepageCard_container{
        display: block;
    } 

    .customer-container{
        @include homepage_spacer(0rem);
        .images{
            width: 37%;
            overflow: hidden;
            margin-left: 40%;
        }
    }

    
    
        .card_section-lower {
            background-color: $skyblue;
            border-top-left-radius: 2rem;
            border-top-right-radius: 2rem;
            display: block;
            padding: 2rem;
    
            button {
                outline: none;
                padding: 1rem;
                border: none;
                border-radius: 2rem;
                width: 100%;
                font-size: 0.89rem;
                color: $lightOrange;
                background-color: #fff;
    
            }
    
            p:nth-child(1) {
                font-size: 1.5rem;
                margin-bottom: 0;
                line-height: 1.4;
            }
    
            .inner-left,
            .inner-right {
                width: 100%;
                text-align: center;
            }
    
            .inner-left {
                p:nth-child(2) {
                    font-weight: 100;
                }
            }
    
            .inner-right {
                width: 100%;
    
                img {
                    width: 100%;
                }
            }
        }

        .how_works-section{

            .processes{
            //   display: inline-block;
            // width: 50%;
                flex-wrap: wrap;
            }
        }

        .offerTypes{
            display: block;
        }

        .faqs{
            div:nth-child(2) {
                text-align: center;
                margin: 0;
                padding: 1rem;
                font-size: 1.5rem;
            }

            div:nth-child(3){
                font-size: 1rem;
            }
        }

        .intrastate-adventure{
            div:nth-child(1) {
                    font-size: 2rem;
                }
            span:nth-child(3) {
                margin: 0 10% auto;   
            
            }
    }
}

@media screen and (max-width: 700px){
    
    .how_works-section {
        
            .quick-journey {
                    font-size: 2rem;
            
                    span {
                        opacity: 0.4;
                    }
                }


    
            .processes {
                display:block;
            }
        }

    .customer-container {

        div:nth-child(2) {
                font-size: 0.7rem;
                margin: 0 20% auto;
            }

           .content {
               font-weight: 100;
               margin: 0 10% auto;
               font-size: 0.8rem;
           }

            @include homepage_spacer(0rem);

            .images {
                // width: 25%;
                overflow: hidden;
                margin-left: 30%;
            }
}
}